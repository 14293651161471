@font-face {
  font-family: 'BeautyHeart';
  src: url('./fonts/BeautyHeart.woff2') format('woff2'),
    url('./fonts/BeautyHeart.woff') format('woff'),
    url('./fonts/BeautyHeart.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'bimash_palace_script_mtRg';
  src: url('./fonts/13601-webfont.woff2') format('woff2'),
    url('./fonts/13601-webfont.woff') format('woff'),
    url('./fonts/13601-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-family: 'BeautyHeart';
  font-size: 120px;
}

h2 {
  font-family: 'bimash_palace_script_mtRg';
  font-size: 66px;
  color: #e1a345 !important;
}

h3 {
  font-size: 20px;
  color: #e1a345 !important;
  font-weight: 300 !important;
}

h4 {
  color: #e1a345 !important;
  font-weight: 300 !important;
}

@media (max-width: 980px) {
  h1 {
    font-family: 'BeautyHeart';
    font-size: 80px;
  }

  h2 {
    font-family: 'bimash_palace_script_mtRg';
    font-size: 58px;
    color: #e1a345 !important;
  }

  h3 {
    font-size: 16px;
    color: #e1a345 !important;
    font-weight: 300 !important;
  }

  h4 {
    color: #e1a345 !important;
    font-weight: 300 !important;
  }
}
